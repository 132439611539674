import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, change, formValueSelector } from "redux-form";
import { Link } from "react-router";

import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";

import api from "../../api/apiUtil";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import SelectField from "../../components/Fields/SelectField";

import Title from "../../components/Layout/Title";
import {
  HOME_DASHBOARD_LIST_LOAD,
  HOME_CONTRACTS_LOAD,
  HOME_UNLOAD,
  HOME_CONTRACTS_ERRORS_LOAD,
  HOME_CONTRACTS_EXPIRED_LOAD,
  HOME_CONTRACTS_EXPIRED_7_LOAD,
  HOME_CONTRACTS_EXPIRED_15_LOAD,
  HOME_CONTRACTS_EXPIRED_30_LOAD,
  HOME_CONTRACTS_NOT_REGISTRED_LOAD,
  HOME_CONTRACTS_REGISTRED_ERROR_LOAD,
  HOME_CONTRACTS_REGISTRED_WITHOUT_IMAGE_LOAD,
  HOME_CONTRACTS_REGISTRED_LOAD,
  HOME_DASHBOARD_SELECTED_MONTH_LOAD,
  HOME_DASHBOARD_COMPANIES_ID_LOAD,
  HOME_CONTRACTS_CANCEL_LOAD,
  HOME_NEWS_LOAD,
  HOME_MONIT_EXPIRED_LOAD,
  HOME_VIEW_NEW,
} from "../../actions/Constants";

import ImagesStatus from "./ImagesStatus";
import { BeatLoader } from "react-spinners";
import { toastr } from "react-redux-toastr";

import SvgIcon from "react-icons-kit";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";
import MaskDate from "../../components/Util/MaskDate";
import moment from "moment";
import Modal from "react-bootstrap4-modal";
import OtherUtils from "../../components/Util/OtherUtils";

const years = [
  { label: "Selecione", value: "" },
  { label: "2019", value: "2019" },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
];

const trimestres = [
  { label: "Selecione", value: "" },
  { label: "Primeiro Trimestre", value: "1" },
  { label: "Segundo Trimestre", value: "2" },
  { label: "Terceiro Trimestre", value: "3" },
  { label: "Quarto Trimestre", value: "4" },
];

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      pesquisando: false,
      lastImage: null,
      modalLastImage: false,
      idNovidade: null,
    };
  }

  componentWillMount() {
    Promise.resolve(api.News.all(true)).then((res) => {
      var news = res.map((item) => {
        var dataFormatada = moment(item.data).locale("pt-br");
        dataFormatada = dataFormatada.format("dddd D") + " de " + dataFormatada.format("MMMM YYYY");

        return { ...item, dataFormatada };
      });

      if (this.props.currentUser.primeiroAcesso != 0 && !this.props.viewNew && news.filter((item) => item.arquivo).length > 0) {
        let ids = news.filter((item) => item.arquivo).map((item) => item.id);
        let dados = {
          ids: ids,
        };

        Promise.resolve(api.News.imagemPorNovidadeUf(dados)).then((response) => {
          if (response && response.length > 0) {
            var base64Flag = "data:image/jpeg;base64,";
            var imageStr = OtherUtils.arrayBufferToBase64(response[0].arquivo.data);
            this.setState({
              lastImage: base64Flag + imageStr,
              modalLastImage: true,
              idNovidade: response[0].id,
            });
          }
        });
      }

      this.props.onNewsLoad(news.filter((item) => !item.arquivo));
    });

    Promise.resolve(api.Companies.authorized(this.props.currentUser.idFinanceira)).then((correspondentes) => {
      var compiledCorrespondentes = correspondentes.map((cor) => {
        return {
          label: cor.razaoSocial,
          value: cor.id,
        };
      });
      this.props.onCompaniesIdLoad([{ label: "GERAL", value: 0 }, ...compiledCorrespondentes]);
      this.handleContractImages({});
    });
    this.props.onListQtdExpiredMonit(api.Configuracao.getQtdExpiracaoCrlv());
    this.handleFilterChart();
  }

  handleInsertNovidadeView() {
    var props = this.props;
    Promise.resolve(api.News.criaVisualizacao(this.state.idNovidade))
      .then((response) => {
        this.setState({
          lastImage: null,
          modalLastImage: false,
          idNovidade: null,
        });
        this.props.onViewNew();
      })
      .catch((e) => {
        if (e.message === "Unauthorized" || e.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  handleContractImages(values) {
    var promisesQtdImagensContratos = [api.Contracts.getQtdImagensContratos(0, values.uf == "Todas" ? undefined : values.uf)];
    // var promisesQtdImagensContratos = this.props.companies.map(function (obj) {
    //   return api.Contracts.getQtdImagensContratos(obj.cnpj, values.uf == "Todas" ? undefined : values.uf);
    // });
    Promise.all(promisesQtdImagensContratos).then((results) => {
      let contractsExpired = {
        qtd: 0,
      };
      let contractsExpired7 = {
        qtd: 0,
      };
      let contractsExpired15 = {
        qtd: 0,
      };
      let contractsExpired30 = {
        qtd: 0,
      };
      results.forEach(function (value) {
        contractsExpired.qtd += value.filter((element) => element.vencido == "vencido30")[0].qtd;
        contractsExpired.data = value.filter((element) => element.vencido == "vencido30")[0].data;
        contractsExpired7.qtd += value.filter((element) => element.vencido == "vencerEm7")[0].qtd;
        contractsExpired7.data = value.filter((element) => element.vencido == "vencerEm7")[0].data;
        contractsExpired15.qtd += value.filter((element) => element.vencido == "vencerEm15")[0].qtd;
        contractsExpired15.data = value.filter((element) => element.vencido == "vencerEm15")[0].data;
        contractsExpired30.qtd += value.filter((element) => element.vencido == "vencerEm30")[0].qtd;
        contractsExpired30.data = value.filter((element) => element.vencido == "vencerEm30")[0].data;
      });
      this.props.onContractsExpiredLoad(contractsExpired);
      this.props.onContractsExpired7Load(contractsExpired7);
      this.props.onContractsExpired15Load(contractsExpired15);
      this.props.onContractsExpired30Load(contractsExpired30);
    });

    var promisesQtdVisaoGeral = [api.Contracts.getQtdVisaoGeral(0, values.uf == "Todas" ? undefined : values.uf)];
    // var promisesQtdVisaoGeral = this.props.companies.map(function (obj) {
    //   return api.Contracts.getQtdVisaoGeral(obj.cnpj, values.uf == "Todas" ? undefined : values.uf);
    // });

    Promise.all(promisesQtdVisaoGeral).then((results) => {
      let contractsNotRegistred = 0;
      let contractsRegistredError = 0;
      let contractsRegistredWithoutImage = 0;
      let contractsRegistred = 0;
      let contractsCancel = 0;
      results.forEach(function (value) {
        contractsNotRegistred += value.filter((element) => element.status == "naoEnviadoEmProc")[0].qtd;
        contractsRegistredError += value.filter((element) => element.status == "comErro")[0].qtd;
        contractsRegistredWithoutImage += value.filter((element) => element.status == "registradoSemImagem")[0].qtd;
        contractsRegistred += value.filter((element) => element.status == "registrado")[0].qtd;
        contractsCancel += value.filter((element) => element.status == "canceladoBaixado")[0].qtd;
      });
      this.props.onContractsCancelLoad(contractsCancel);
      this.props.onContractsRegistredLoad(contractsRegistred);
      this.props.onRegistredWithoutImageLoad(contractsRegistredWithoutImage);
      this.props.onRegistredErrorLoad(contractsRegistredError);
      this.props.onNotRegistredLoad(contractsNotRegistred);
    });
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  renderNews() {
    if (this.props.news) {
      return this.props.news.map((n) => {
        return (
          <li className="list-group-item">
            <div className="d-flex justify-content-between">
              <div className="stats-title">
                <h4>{n.titulo}</h4>
              </div>
              {n.link && (
                <div className="stats-title-default">
                  {this.props.currentUser.funcionalidades.includes(7) && (
                    <Link className="font-weight-bold text-info" href={n.link.includes("http") ? n.link : `http://${n.link}`} target="_blank" title={`Visualizar Postagem - ${n.titulo}`}>
                      Saiba Mais
                    </Link>
                  )}
                </div>
              )}
            </div>
            <div className="m-b-sm">
              <small>{n.dataFormatada}</small>
            </div>
            <div className="stats-label">{n.descricao}</div>
          </li>
        );
      });
    }
  }

  handleFilterChart(values) {
    this.setState({ pesquisando: true });
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    var obj = {
      financeira: values && values.financeira > 0 ? values.financeira : this.props.currentUser.idFinanceira,
      correspondente: !values || (values && values.financeira == 0),
      trimestre: values && values.trimestre && values.ano ? values.trimestre : null,
      ano: values && values.ano && values.trimestre ? values.ano : null,
      uf: values && values.uf ? values.uf : null,
    };
    var razaoSocial = "GERAL";
    if (values && values.financeira > 0) {
      var foundCompany = this.props.finsId.find((item) => item.value == values.financeira && item.label);
      razaoSocial = foundCompany.label;
    }

    Promise.resolve(api.Home.homeChart(obj.financeira, obj.correspondente, obj.trimestre, obj.ano, obj.uf)).then(
      (res) => {
        this.props.onListLoad({ razaoSocial, ...res });
        this.setState({ pesquisando: false });
        toastr.removeByType("info");
        if (!res || (res.qtdMes1 == 0 && res.qtdMes2 == 0 && res.qtdMes3 == 0)) {
          toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  exportChartToCSV = (type, contracts) => {
    if (type === "chart3") {
      var Heading = [[`Quantidade - ${this.props.homeChart.mes1.toUpperCase()}`, `Quantidade - ${this.props.homeChart.mes2.toUpperCase()}`, `Quantidade - ${this.props.homeChart.mes3.toUpperCase()}`]];
      var Data = [];

      Data.push({
        qtdMes1: this.props.homeChart.qtdMes1,
        qtdMes2: this.props.homeChart.qtdMes2,
        qtdMes3: this.props.homeChart.qtdMes3,
      });

      var ws = XLSX.utils.aoa_to_sheet(Heading);
      XLSX.utils.sheet_add_json(ws, Data, {
        header: ["qtdMes1", "qtdMes2", "qtdMes3"],
        skipHeader: true,
        origin: "A2",
      });

      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Resultado");
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `RelatorioContratos-${this.props.homeChart.razaoSocial}-Trimestral.xlsx`);
    } else if (type === "chart4") {
      var Heading = [["Dia", "Quantidade"]];
      var Data = [];

      Data = this.props.homeChart.qtdPorDia[this.props.selectedMonth].map((row, index) => {
        return { dia: index + 1, qtd: row };
      });

      var ws = XLSX.utils.aoa_to_sheet(Heading);
      XLSX.utils.sheet_add_json(ws, Data, {
        header: ["dia", "qtd"],
        skipHeader: true,
        origin: "A2",
      });

      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Resultado");
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      FileSaver.saveAs(
        new Blob([wbout], { type: "application/octet-stream" }),
        `RelatorioContratos-${this.props.homeChart.razaoSocial}-Diario-${
          this.props.selectedMonth === 0 ? this.props.homeChart.mes1 : this.props.selectedMonth === 1 ? this.props.homeChart.mes2 : this.props.homeChart.mes3
        }.xlsx`
      );
    }
  };

  exportToPDF(type, contracts) {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.page = 1;

    doc.setFontSize(12);

    var startTableY = 50;

    var footer = function () {
      //print number bottom right

      doc.setFontSize(7);
      doc.text(width - 50, height - 30, "Página " + doc.page);
      doc.page++;
    };

    if (type !== "contratos") {
      var titleUnitTable1 = `Relatório de Contratos E-Registro - ${this.props.homeChart.razaoSocial} - Trimestral - ${this.props.homeChart.mes1} - ${this.props.homeChart.mes2} - ${this.props.homeChart.mes3}`;

      const headUnitTable1 = [
        [{ content: `Quantidade - ${this.props.homeChart.mes1}` }, { content: `Quantidade - ${this.props.homeChart.mes2}` }, { content: `Quantidade - ${this.props.homeChart.mes3}` }],
      ];

      const dataUnitTable1 = [[this.props.homeChart.qtdMes1, this.props.homeChart.qtdMes2, this.props.homeChart.qtdMes3]];

      let contentUnitTable1 = {
        startY: startTableY,
        head: headUnitTable1,
        body: dataUnitTable1,
        bodyStyles: { valign: "top" },
        styles: {
          cellPadding: 1.5,
          overflow: "linebreak",
          valign: "middle",
          halign: "center",
          lineColor: [0, 0, 0],
          lineWidth: 0.2,
        },
        didDrawPage: function (data) {
          footer();
        },
      };

      doc.autoTable(contentUnitTable1);

      doc.text(titleUnitTable1, marginLeft, 30);

      //Segunda tabela do tipo unitário

      const headUnitTable2 = [[{ content: "Dia" }, { content: "Quantidade" }]];

      const dataUnitTable2 = this.props.homeChart.qtdPorDia[this.props.selectedMonth].map((row, index) => [index + 1, row]);

      var titleUnitTable2 = `Relatório de Contratos E-Registro - ${this.props.homeChart.razaoSocial} - Diário - ${
        this.props.selectedMonth === 0 ? this.props.homeChart.mes1 : this.props.selectedMonth === 1 ? this.props.homeChart.mes2 : this.props.homeChart.mes3
      }`;

      doc.text(titleUnitTable2, marginLeft, doc.autoTable.previous.finalY + 30);

      let contentUnitTable2 = {
        startY: doc.autoTable.previous.finalY + 50,
        head: headUnitTable2,
        body: dataUnitTable2,
        bodyStyles: { valign: "top" },
        styles: {
          cellPadding: 1.5,
          overflow: "linebreak",
          valign: "middle",
          halign: "center",
          lineColor: [0, 0, 0],
          lineWidth: 0.2,
        },
        didDrawPage: function (data) {
          footer();
        },
      };
      doc.autoTable(contentUnitTable2);

      doc.save(`RelatorioContratos-${this.props.homeChart.razaoSocial}-Trimestral-e-Diario.pdf`);
    } else {
      var titleTable1 = `Relatório de Contratos E-Registro - ${this.props.homeChart.razaoSocial} - Últimos Contratos Não Registrados`;

      const headTable1 = [[{ content: "Contrato" }, { content: "Credor / CNPJ" }, "Devedor / CPF - CNPJ", { content: "Chassi / Renavam" }, { content: "Placa" }]];

      const bodyTable1 = contracts.map((row) => [
        row.numero,
        row.razaoSocial + " / " + FieldNormalize.CNPJ(row.cnpj, row.cnpj),
        row.devNome + " / " + FieldNormalize.CPF_CNPJ(row.devCpfCnpj),
        `${row.chassi} ${row.renavam ? `/ ${row.renavam}` : ""}`,
        row.ufPlaca && row.numPlaca ? row.ufPlaca + " " + row.numPlaca : "",
      ]);

      let contentTable1 = {
        startY: startTableY,
        head: headTable1,
        body: bodyTable1,
        bodyStyles: { valign: "top" },
        styles: {
          cellPadding: 1.5,
          overflow: "linebreak",
          valign: "middle",
          halign: "center",
          lineColor: [0, 0, 0],
          lineWidth: 0.2,
        },
        didDrawPage: function (data) {
          footer();
        },
      };

      doc.text(titleTable1, marginLeft, 30);
      doc.autoTable(contentTable1);
      doc.save(`RelatorioContratos-${this.props.homeChart.razaoSocial}-Contratos Não Registrados.pdf`);
    }
  }

  async filterUf(value) {
    const { reset } = this.props;
    var uf = value.uf;
    value.uf && value.uf.length == 2 ? window.localStorage.setItem("uf_home", value.uf) : localStorage.removeItem("uf_home");
    await this.handleContractImages({ uf: value.uf });
    await this.handleFilterChart({ uf: value.uf });
    if (this.props.anyTouched) reset();
    this.props.dispatch(change("HomeForm", "uf", uf));
  }

  render() {
    var data3,
      data4 = [];
    const { handleSubmit, reset } = this.props;
    if (this.props.homeChart.qtdPorDia && this.props.homeChart.qtdPorDia.length == 3) {
      //Cores do fundo
      var backgroundColorNotSelected = "rgba(24,73,170,0.2)";
      var backgroundColorSelected = "rgba(24,169,43,0.2)";
      //Cores da borda
      var borderColorNotSelected = "rgba(11,35,80,1)";
      var borderColorSelected = "rgba(26,80,32,1)";
      //Cores do fundo - HOVER
      var hoverBackgroundColorNotSelected = "rgba(24,73,170,0.4)";
      var hoverBackgroundColorSelected = "rgba(24,169,43,0.4)";
      //Cores da borda - HOVER
      var hoverBorderColorNotSelected = "rgba(11,35,80,1)";
      var hoverBorderColorSelected = "rgba(26,80,32,1)";
      data3 = {
        labels: [this.props.homeChart.mes1, this.props.homeChart.mes2, this.props.homeChart.mes3],
        datasets: [
          {
            label: this.props.homeChart.razaoSocial,
            borderWidth: 1,
            backgroundColor: [
              this.props.selectedMonth != 0 ? backgroundColorNotSelected : backgroundColorSelected,
              this.props.selectedMonth != 1 ? backgroundColorNotSelected : backgroundColorSelected,
              this.props.selectedMonth != 2 ? backgroundColorNotSelected : backgroundColorSelected,
            ],
            borderColor: [
              this.props.selectedMonth != 0 ? borderColorNotSelected : borderColorSelected,
              this.props.selectedMonth != 1 ? borderColorNotSelected : borderColorSelected,
              this.props.selectedMonth != 2 ? borderColorNotSelected : borderColorSelected,
            ],
            hoverBackgroundColor: [
              this.props.selectedMonth != 0 ? hoverBackgroundColorNotSelected : hoverBackgroundColorSelected,
              this.props.selectedMonth != 1 ? hoverBackgroundColorNotSelected : hoverBackgroundColorSelected,
              this.props.selectedMonth != 2 ? hoverBackgroundColorNotSelected : hoverBackgroundColorSelected,
            ],
            hoverBorderColor: [
              this.props.selectedMonth != 0 ? hoverBorderColorNotSelected : hoverBorderColorSelected,
              this.props.selectedMonth != 1 ? hoverBorderColorNotSelected : hoverBorderColorSelected,
              this.props.selectedMonth != 2 ? hoverBorderColorNotSelected : hoverBorderColorSelected,
            ],
            data: [this.props.homeChart.qtdMes1, this.props.homeChart.qtdMes2, this.props.homeChart.qtdMes3],
          },
        ],
      };
      const data = JSON.parse(JSON.stringify(this.props.homeChart.qtdPorDia[this.props.selectedMonth]));
      data4 = {
        labels: this.props.homeChart.arrayDias[this.props.selectedMonth],
        datasets: [
          {
            label: `${this.props.homeChart.razaoSocial} - ${
              this.props.selectedMonth === 0 ? this.props.homeChart.mes1 : this.props.selectedMonth === 1 ? this.props.homeChart.mes2 : this.props.homeChart.mes3
            }`,
            borderWidth: 1,
            backgroundColor: backgroundColorSelected,
            borderColor: borderColorSelected,
            hoverBackgroundColor: hoverBackgroundColorSelected,
            hoverBorderColor: hoverBorderColorSelected,
            data,
          },
        ],
      };
    }
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} />
        <div className="content animate-panel">
          {this.props.currentUser.funcionalidades.includes(1) ? ( // Visualizar Gráficos
            <div className="row">
              <div className="col-md-12">
                <div className="hpanel">
                  <div className="row">
                    <div className="col-md-2">
                      <Field name="uf" label="UF" data={this.props.currentUser.ufs} textField="sigla" component={SelectField} type="text" />
                    </div>
                    <div className="col-lg-1 align-self-end form-group">
                      <button className="btn btn-success" onClick={handleSubmit(this.filterUf.bind(this))} type="button">
                        Filtrar
                      </button>
                    </div>
                  </div>
                  <div className="panel-body h-200">
                    <div>
                      {this.props.homeChart.qtdPorDia && this.props.homeChart.qtdPorDia.length == 3 ? (
                        <div className="stats-title">
                          <h3>
                            Trimestre e Mês atual &nbsp;{" "}
                            <Link className="btn btn-default btn-sm" onClick={() => this.exportToPDF()} title="Gerar PDF de Ambos os Gráficos">
                              {" "}
                              <SvgIcon icon={filePdf} />
                            </Link>
                            <Link className="btn btn-default btn-sm" onClick={() => this.exportChartToCSV("chart3")} title="Gerar Excel do Primeiro Gráfico">
                              {" "}
                              <SvgIcon icon={fileExcelO} />
                            </Link>
                            <Link className="btn btn-default btn-sm" onClick={() => this.exportChartToCSV("chart4")} title="Gerar Excel do Segundo Gráfico">
                              {" "}
                              <SvgIcon icon={fileExcelO} />
                            </Link>
                          </h3>
                        </div>
                      ) : (
                        <div className="stats-title">
                          <h4>Trimestre e Mês atual</h4>
                        </div>
                      )}
                      <br></br>
                      <form onSubmit={handleSubmit(this.handleFilterChart.bind(this))}>
                        <div className="row">
                          {this.props.finsId && this.props.finsId.length > 2 && (
                            <div className="col-lg-4">
                              <Field name="financeira" label="Financeira" data={this.props.finsId} textField="label" valueField="value" component={SelectField} type="text" />
                            </div>
                          )}
                          <div className={this.props.finsId && this.props.finsId.length > 2 ? "col-lg-3" : "col-lg-5"}>
                            <Field name="trimestre" label="Trimestre" data={trimestres} textField="label" valueField="value" component={SelectField} type="text" />
                          </div>
                          <div className={this.props.finsId && this.props.finsId.length > 2 ? "col-lg-3" : "col-lg-5"}>
                            <Field name="ano" label="Ano" data={years} textField="label" valueField="value" component={SelectField} type="text" />
                          </div>
                          <div className="col-lg-2 align-self-end form-group">
                            <button disabled={this.props.inProgress || this.props.submitting || this.props.invalid || this.state.pesquisando} className="btn btn-success btn-block" type="submit">
                              {this.state.pesquisando ? "Aguarde..." : "Pesquisar"}
                            </button>
                          </div>
                        </div>
                      </form>
                      {this.props.homeChart.qtdPorDia && this.props.homeChart.qtdPorDia.length == 3 && (
                        <div className="row">
                          <div className="col-lg-3">
                            <Bar
                              data={data3}
                              height={250}
                              options={{
                                scales: {
                                  yAxes: [
                                    {
                                      ticks: {
                                        min: 0,
                                        stepSize: Math.max(this.props.homeChart.qtdMes1, this.props.homeChart.qtdMes2, this.props.homeChart.qtdMes3) <= 10 ? 1 : undefined,
                                      },
                                    },
                                  ],
                                },
                                tooltips: {
                                  titleFontColor: "#E0E0E0",
                                  callbacks: {
                                    title: function (tooltipItem, data) {
                                      return data["labels"][tooltipItem[0]["index"]];
                                    },
                                    label: function (tooltipItem, data) {
                                      return `Contratos Registrados: ${data["datasets"][0]["data"][tooltipItem["index"]]}`;
                                    },
                                  },
                                },
                                maintainAspectRatio: false,
                              }}
                              onElementsClick={(elem) => {
                                if (elem && elem[0]) {
                                  this.props.onSelectedMonthLoad(elem[0]._index);
                                }
                              }}
                            />
                          </div>
                          <div className="col-lg-9">
                            <Line
                              data={data4}
                              height={250}
                              options={{
                                scales: {
                                  yAxes: [
                                    {
                                      ticks: {
                                        stepSize: Math.max(...this.props.homeChart.qtdPorDia[this.props.selectedMonth]) <= 10 ? 1 : undefined,
                                      },
                                    },
                                  ],
                                },
                                tooltips: {
                                  titleFontColor: "#E0E0E0",
                                  callbacks: {
                                    title: function (tooltipItem, data) {
                                      return `Dia ${data["labels"][tooltipItem[0]["index"]]}`;
                                    },
                                    label: function (tooltipItem, data) {
                                      return `Contratos Registrados: ${data["datasets"][0]["data"][tooltipItem["index"]]}`;
                                    },
                                  },
                                },
                                maintainAspectRatio: false,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-md-6">
              {this.props.currentUser.funcionalidades.includes(2) ? ( // Visualizar Imagens de registro de contrato
                <div className="hpanel stats">
                  <div className="panel-body h-100">
                    <div className="row m-b-sm">
                      <div className="col-md-12">
                        <div className="stats-title float-left">
                          {" "}
                          <h3>IMAGENS DE REGISTRO DE CONTRATOS</h3>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="font-bold float-left">Vencidos</div>
                        <br />
                        <ul className="list-group m-t-sm">
                          <li className="list-group-item">
                            <div className="d-flex justify-content-between">
                              <div>Mais de 30 Dias</div>
                              <div>
                                {this.props.contractsExpired.qtd > 0 ? (
                                  this.props.currentUser.funcionalidades.includes(3) ? ( // Acessar Imagens de registro de contrato
                                    <Link className="font-weight-bold float-left text-danger" to={`/secure/contracts/NI/${MaskDate.TO_DATABASE_DATE(this.props.contractsExpired.data[0])}`}>
                                      {this.props.contractsExpired.qtd}
                                    </Link>
                                  ) : (
                                    <div className="font-weight-bold float-left text-danger">{this.props.contractsExpired.qtd}</div>
                                  )
                                ) : (
                                  "0"
                                )}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <div className="font-bold float-left">A Vencer</div>
                        <br />
                        <ul className="list-group m-t-sm">
                          <li className="list-group-item">
                            <div className="d-flex justify-content-between">
                              <div>Em 7 Dias</div>
                              <div>
                                {this.props.contractsExpired7.qtd > 0 ? (
                                  this.props.currentUser.funcionalidades.includes(3) ? ( // Acessar Imagens de registro de contrato
                                    <Link
                                      className="font-weight-bold float-left text-danger"
                                      to={`/secure/contracts/NI/${MaskDate.TO_DATABASE_DATE(this.props.contractsExpired7.data[0])}/${MaskDate.TO_DATABASE_DATE(this.props.contractsExpired7.data[1])}`}
                                    >
                                      {this.props.contractsExpired7.qtd}
                                    </Link>
                                  ) : (
                                    <div className="font-weight-bold float-left text-danger">{this.props.contractsExpired7.qtd}</div>
                                  )
                                ) : (
                                  "0"
                                )}
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="d-flex justify-content-between">
                              <div>Em 15 Dias</div>
                              <div>
                                {this.props.contractsExpired15.qtd > 0 ? (
                                  this.props.currentUser.funcionalidades.includes(3) ? ( // Acessar Imagens de registro de contrato
                                    <Link
                                      className="font-weight-bold float-left text-danger"
                                      to={`/secure/contracts/NI/${MaskDate.TO_DATABASE_DATE(this.props.contractsExpired15.data[0])}/${MaskDate.TO_DATABASE_DATE(
                                        this.props.contractsExpired15.data[1]
                                      )}`}
                                    >
                                      {this.props.contractsExpired15.qtd}
                                    </Link>
                                  ) : (
                                    <div className="font-weight-bold float-left text-danger">{this.props.contractsExpired15.qtd}</div>
                                  )
                                ) : (
                                  "0"
                                )}
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="d-flex justify-content-between">
                              <div>Em 30 Dias</div>
                              <div>
                                {this.props.contractsExpired30.qtd > 0 ? (
                                  this.props.currentUser.funcionalidades.includes(3) ? ( // Acessar Imagens de registro de contrato
                                    <Link
                                      className="font-weight-bold float-left text-danger"
                                      to={`/secure/contracts/NI/${MaskDate.TO_DATABASE_DATE(this.props.contractsExpired30.data[0])}/${MaskDate.TO_DATABASE_DATE(
                                        this.props.contractsExpired30.data[1]
                                      )}`}
                                    >
                                      {this.props.contractsExpired30.qtd}
                                    </Link>
                                  ) : (
                                    <div className="font-weight-bold float-left text-danger">{this.props.contractsExpired30.qtd}</div>
                                  )
                                ) : (
                                  "0"
                                )}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.props.currentUser.funcionalidades.includes(4) && (
                <ImagesStatus companies={this.props.companies} funcionalidades={this.props.currentUser.funcionalidades} uf={this.props.selectedUf} />
              )}
            </div>
            <div className="col-md-6">
              {this.props.currentUser.funcionalidades.includes(6) && (
                <div className="hpanel stats">
                  <div className="panel-body h-100">
                    <div className="stats-title float-left">
                      {" "}
                      <h3>O QUE HÁ DE NOVO?</h3>{" "}
                    </div>
                    <br />
                    <br />
                    <div style={{ overflowY: "scroll", maxHeight: "396px", width: "auto" }}>
                      <ul class="list-group">{this.renderNews()}</ul>
                    </div>
                  </div>
                </div>
              )}
              {this.props.currentUser.monitoramentoCrv == "S" && (
                <div className="hpanel stats">
                  <div className="panel-body h-150">
                    <div className="row m-b-sm">
                      <div className="col-md-12">
                        <div className="stats-title float-left">
                          {" "}
                          <h3>MONITORAMENTO DE EMISSÃO DE DOCUMENTO</h3>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="font-bold float-left">A Vencer</div>
                        <br />
                        <ul className="list-group m-t-sm">
                          {/* <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>Mais de 30 dias (Vencidos)</div>
                          <div>
                            <div className="font-weight-bold float-left text-danger">{this.props.qtdExpiredMonit ? this.props.qtdExpiredMonit.vencido : 0}</div>
                          </div>
                        </div>
                      </li> */}
                          <li className="list-group-item">
                            <div className="d-flex justify-content-between">
                              <div>Em 7 dias</div>
                              <div>
                                <div className="font-weight-bold float-left text-danger">{this.props.qtdExpiredMonit ? this.props.qtdExpiredMonit.vencerEm7 : 0}</div>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="d-flex justify-content-between">
                              <div>Em 15 Dias</div>
                              <div>
                                <div className="font-weight-bold float-left">{this.props.qtdExpiredMonit ? this.props.qtdExpiredMonit.vencerEm15 : 0}</div>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="d-flex justify-content-between">
                              <div>Em 30 Dias</div>
                              <div>
                                <div className="font-weight-bold float-left">{this.props.qtdExpiredMonit ? this.props.qtdExpiredMonit.vencerEm30 : 0}</div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {this.props.currentUser.funcionalidades.includes(8) && (
                <div className="hpanel stats">
                  <div className="panel-body h-150">
                    <div className="stats-title float-left">
                      {" "}
                      <h3>VISÃO GERAL DE CONTRATOS</h3>{" "}
                    </div>
                    <br />
                    <br />
                    <ul className="list-group">
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>
                            <i className="fa fas fa-square"></i> - Não Registrado/Aguardando DETRAN
                          </div>
                          <div>
                            {this.props.contractsNotRegistred > 0 ? (
                              this.props.currentUser.funcionalidades.includes(9) ? (
                                <Link className="font-weight-bold float-left text-danger" to={`/secure/contracts/NR/`}>
                                  {this.props.contractsNotRegistred}
                                </Link>
                              ) : (
                                <div className="font-weight-bold float-left text-danger">{this.props.contractsNotRegistred}</div>
                              )
                            ) : (
                              "0"
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>
                            <i className="fa fas fa-square text-danger"></i> - Registro com erro
                          </div>
                          <div>
                            {this.props.contractsRegistredError > 0 ? (
                              this.props.currentUser.funcionalidades.includes(9) ? (
                                <Link className="font-weight-bold float-left text-danger" to={`/secure/contracts/ERR/`}>
                                  {this.props.contractsRegistredError}
                                </Link>
                              ) : (
                                <div className="font-weight-bold float-left text-danger">{this.props.contractsRegistredError}</div>
                              )
                            ) : (
                              "0"
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>
                            <i className="fa fas fa-square text-warning"></i> - Registrado sem imagem
                          </div>
                          <div>
                            {this.props.contractsRegistredWithoutImage > 0 ? (
                              this.props.currentUser.funcionalidades.includes(9) ? (
                                <Link className="font-weight-bold float-left text-danger" to={`/secure/contracts/NI/`}>
                                  {this.props.contractsRegistredWithoutImage}
                                </Link>
                              ) : (
                                <div className="font-weight-bold float-left text-danger">{this.props.contractsRegistredWithoutImage}</div>
                              )
                            ) : (
                              "0"
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>
                            <i className="fa fas fa-square text-success"></i> - Registrado
                          </div>
                          <div>
                            {this.props.contractsRegistred > 0 ? (
                              this.props.currentUser.funcionalidades.includes(9) ? (
                                <Link className="font-weight-bold float-left text-danger" to={`/secure/contracts/R/`}>
                                  {this.props.contractsRegistred}
                                </Link>
                              ) : (
                                <div className="font-weight-bold float-left text-danger">{this.props.contractsRegistred}</div>
                              )
                            ) : (
                              "0"
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <div>
                            <i className="fa fas fa-square text-primary"></i> - Cancelado/Baixado
                          </div>
                          <div>
                            {this.props.contractsCancel > 0 ? (
                              this.props.currentUser.funcionalidades.includes(9) ? (
                                <Link className="font-weight-bold float-left text-danger" to={`/secure/contracts/CB/`}>
                                  {this.props.contractsCancel}
                                </Link>
                              ) : (
                                <div className="font-weight-bold float-left text-danger">{this.props.contractsCancel}</div>
                              )
                            ) : (
                              "0"
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item list-group-item-light font-weight-bold">
                        <div className="d-flex justify-content-between">
                          <div>TOTAL</div>
                          <div>
                            {this.props.contractsNotRegistred +
                              this.props.contractsRegistredError +
                              this.props.contractsRegistredWithoutImage +
                              this.props.contractsRegistred +
                              this.props.contractsCancel}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal visible={this.state.modalLastImage} dialogClassName="modal-max-width-height">
          <div>
            <img src={this.state.lastImage} alt="ssa" width="800px" height="500px" />
          </div>
          <div className="col-1 d-grid" style={{ position: "fixed", top: "-50px", right: "3%" }}>
            <button py-2 type="button" className="btn btn-success" onClick={() => this.handleInsertNovidadeView()}>
              Fechar
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onContractsLoad: (payload) => dispatch({ type: HOME_CONTRACTS_LOAD, payload }),
  onErrorsLoad: (payload) => dispatch({ type: HOME_CONTRACTS_ERRORS_LOAD, payload }),
  onListLoad: (payload) => dispatch({ type: HOME_DASHBOARD_LIST_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: HOME_UNLOAD }),
  onContractsExpiredLoad: (payload) => dispatch({ type: HOME_CONTRACTS_EXPIRED_LOAD, payload }),
  onContractsExpired7Load: (payload) => dispatch({ type: HOME_CONTRACTS_EXPIRED_7_LOAD, payload }),
  onContractsExpired15Load: (payload) => dispatch({ type: HOME_CONTRACTS_EXPIRED_15_LOAD, payload }),
  onContractsExpired30Load: (payload) => dispatch({ type: HOME_CONTRACTS_EXPIRED_30_LOAD, payload }),
  onNotRegistredLoad: (payload) => dispatch({ type: HOME_CONTRACTS_NOT_REGISTRED_LOAD, payload }),
  onRegistredErrorLoad: (payload) => dispatch({ type: HOME_CONTRACTS_REGISTRED_ERROR_LOAD, payload }),
  onRegistredWithoutImageLoad: (payload) => dispatch({ type: HOME_CONTRACTS_REGISTRED_WITHOUT_IMAGE_LOAD, payload }),
  onContractsRegistredLoad: (payload) => dispatch({ type: HOME_CONTRACTS_REGISTRED_LOAD, payload }),
  onContractsCancelLoad: (payload) => dispatch({ type: HOME_CONTRACTS_CANCEL_LOAD, payload }),
  onSelectedMonthLoad: (payload) => dispatch({ type: HOME_DASHBOARD_SELECTED_MONTH_LOAD, payload }),
  onCompaniesIdLoad: (payload) => dispatch({ type: HOME_DASHBOARD_COMPANIES_ID_LOAD, payload }),
  onNewsLoad: (payload) => dispatch({ type: HOME_NEWS_LOAD, payload }),
  onListQtdExpiredMonit: (payload) => dispatch({ type: HOME_MONIT_EXPIRED_LOAD, payload }),
  onViewNew: (payload) => dispatch({ type: HOME_VIEW_NEW, payload }),
});

const selector = formValueSelector("HomeForm");
const mapStateToProps = (state) => ({
  ...state.common,
  ...state.home,
  selectedUf: selector(state, "uf"),
});

const form = reduxForm({
  form: "HomeForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Home));
